.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/**
		@font-face {
		  font-family: japaneseFont;
		  src: url("./fonts/ReggaeOne-Regular.ttf");
		}
		
		@font-face {
			font-family: logoFontGerman;
			src: url("./fonts/GermaniaOne-Regular.ttf");
		}
		@font-face {
			font-family: logoFontGreek;
			src: url("./fonts/UncialAntiqua-Regular.ttf");
		}
		@font-face {
			font-family: logoFontJapanese;
			src: url("./fonts/MeriendaOne-Regular.ttf");
		}
		@font-face {
			font-family: logoFontGhost;
			src: url("./fonts/IMFellEnglish-Regular.ttf");
		}
		@font-face {
			font-family: logoFontModern;
			src: url("./fonts/AllertaStencil-Regular.ttf");
		}
		@font-face {
			font-family: logoFontSlimSharp;
			src: url("./fonts/Junge-Regular.ttf");
		}
		@font-face {
			font-family: logoFontModernSharp;
			src: url("./fonts/ModernAntiqua-Regular.ttf");
		}
		@font-face {
			font-family: logoFontFrench;
			src: url("./fonts/JacquesFrancois-Regular.ttf");
		}
		@font-face {
			font-family: logoFontGabi;
			src: url("./fonts/Gabriela-Regular.ttf");
		}
		.font-logo{
	  		font-family: logoFontModernSharp;
		}
	*/	
		
		@font-face {
		  font-family: cinzelBlackFont;
		  src: url("./fonts/CinzelDecorative-Black.ttf");
		}
		@font-face {
		  font-family: cinzelBoldFont;
		  src: url("./fonts/CinzelDecorative-Bold.ttf");
		}
		@font-face {
		  font-family: cinzelRegularFont;
		  src: url("./fonts/CinzelDecorative-Regular.ttf");
		}
		
		.font-regular{
	  		font-family: cinzelRegularFont;
		}
		.font-major{
	  		font-family: cinzelBlackFont;
		}
		.font-bold{
	  		font-family: cinzelBoldFont;
		}
		.font-test{
	  		font-family: japaneseFont;
		}
    	html {
  			scroll-behavior: smooth;
		}
    
  	.bg-breaks {
		  background-image: url("./images/breaks-min-v2.jpg");
		
		  /* Full height */
		  height: 100%; 
		
		  /* Center and scale the image nicely */
		  background-position: center;
		  background-repeat: no-repeat;
		  background-size: cover;
		  margin-left: 0.1rem;
		}
		.border-danger {
			border-color: #bb0000 !important;
		}
		nav {
		 font-family:cinzelBoldFont
		}
		nav.background {
		 	background: rgb(181,87,43);
			background: linear-gradient(90deg, rgba(181,87,43,0) 0%, rgba(116,84,169,0) 0%, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 22%, rgba(0,0,0,0) 100%); 
		}
		div#band-songs-content {
			padding-bottom: 35px;
		}
		div#media-title {
			margin-top: 20px;
			margin-bottom: 20px;
		}
		div#band-members-title {
			margin-top: 5px;
			margin-bottom: 5px;
		}
		
		a.anchor {
		    display: block;
		    position: relative;
		    top: -65px;
		    visibility: hidden;
		}

		div#contactModalContent {
			border: none;
			background: rgb(0,0,0);
			background: linear-gradient(90deg, rgba(0,0,0,1) 19%, rgba(247,42,42,1) 75%);
		}
		
		.fixed-header {
	  		position: fixed;
		  	top: 0;
		  	z-index: 1030;
		}
		.bg-white-transperent {
 			background: rgb(255,255,255);
			background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 90%, rgba(255,255,255,0) 100%); 
		}
		
		@media screen and (max-width: 699px)
		{
			 img.zmey-logo
			 {
			   margin-top: 70px;
			 }
			 nav.background
			 {
			 	background-color:black;
			 }
			 img#media-title
			 {
			 	max-width: 60%;
			 }
			 img#crew-title
			 {
			 	max-width: 90%;
			 }
			 .bg-breaks {
			 	background-color: black;
			 	background-image: none
			 }
			 div#band-songs-content {
				padding-bottom: 0px;
			 }
	 		.fixed-header {
		  		position: fixed;
		  		top: 0;
		  		right: 0;
		  		left: 0;
		  		z-index: 1030;
			}
			.bg-white-transperent {
        			background: rgb(255,255,255);
				background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 8%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 92%, rgba(255,255,255,0) 100%); 
		      }

		}
		
		/**
		This is fix to stretch content on the big screen to almost full screen width but later on the even
		bigger screens it will always remain in center without beeen stretched further
		*/
		@media (min-width: 1200px) {
    			.container{
        			max-width: 2000px;
    			}
		}
