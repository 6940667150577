.fa {
  padding: 15px;
  font-size: 25px;
  #width: 50px;
  text-align: center;
  text-decoration: none;
  #margin: 5px 2px;
}

.fa:hover {
    color: #bb0000;
}

.fa-facebook {
  background: black;
  color: white;
}

.fa-youtube {
  background: black;
  color: white;
}

.fa-instagram {
  background: black;
  color: white;
}