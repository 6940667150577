@media screen and (max-width: 699px)
{
	 th.gigs-item
	 {
	   display: block;
	   text-align: center !important;
	 }
	 
	 td.gigs-item
	 {
	   display: block;
	   text-align: center !important;
	 }
	 
	 hr
	 {
	 	margin-top: null;
	 	margin-bottom: null;
	 }
	 
}